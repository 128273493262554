<template>
  <ui-component-modal
    modalTitle=""
    :isSaving="isDeleting"
    :isSavingSuccess="isDeletingSuccess"
    :isSavingError="isDeletingError"
    :hideFooterButtons="isDeletingError || isDeletingSuccess"
    :onClickCancel="onClickCancel"
    :onClickSave="deactivateLocationChannel"
    :showModal="showModal"
    @closeModal="onClickCancel"
  >
    <template slot="modalTitle"></template>
    <template v-slot:content>
      <ul>
        <li v-for="reason in reasons" :key="reason.Id">
          <label class="checkbox">
            <input type="checkbox" v-model="selectedReasons" :value="reason" />
            {{ reason.Reason }}
          </label>
        </li>
      </ul>
    </template>
  </ui-component-modal>
</template>

<script>
import { EventBus } from '@/eventbus/event-bus'
import channelProvider from '@/providers/channel'
import noteProvider from '@/providers/note'

export default {
  components: {},

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },

    location: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      channelId: Number(this.$route.params.channelId),
      isDeleting: false,
      isDeletingSuccess: false,
      isDeletingError: false,
      reasons: [],
      selectedReasons: [],
    }
  },

  computed: {},

  created() {
    this.reasons.push({ Id: 1, Reason: 'No terms agreement' })
    this.reasons.push({ Id: 2, Reason: 'No opening hours set' })
    this.reasons.push({ Id: 3, Reason: 'No (active) pricing' })
    this.reasons.push({ Id: 4, Reason: 'No location photos' })
    this.reasons.push({ Id: 5, Reason: 'No location description' })
    this.reasons.push({ Id: 6, Reason: 'No spaces created' })
    this.reasons.push({ Id: 7, Reason: 'No published or active spaces' })
    this.reasons.push({ Id: 8, Reason: 'No spaces photos' })
    this.reasons.push({ Id: 9, Reason: 'No spaces description' })
    this.reasons.push({ Id: 10, Reason: 'Not bookable' })
  },

  methods: {
    deactivateLocationChannel() {
      let self = this

      if (!self.isDeleting) {
        self.isDeleting = true

        channelProvider.methods
          .deactivateLocationChannel(self.channelId, this.location.Id)
          .then((response) => {
            if (response.status === 200) {
              this.createNote()
              this.$emit('locationDeactivated', this.location)

              EventBus.$emit('showToast', {
                type: 'info',
                message: 'Location deactivated!',
              })

              self.onClickCancel()
            }
          })
          .catch((err) => {})
      }
    },

    createNote() {
      let body = ''

      for (let i = 0; i < this.selectedReasons.length; i++) {
        let reason = this.selectedReasons[i]

        body = body + '* ' + reason.Reason + '\n'
      }

      let newNote = {
        Body: body,
        DeliveryAt: 0,
        DoBefore: 0,
        Id: 0,
        IsToDo: false,
        ItemId: this.location.Id,
        LinkItemId: this.location.Id,
        LinkTypeId: 'Location',
        AccountIds: [],
        Subject: 'Location ' + this.location.Name + ' deactivated.',
        TypeId: 'Location',
        WorkEnvironment: 'Channel',
        EnvironmentId: this.channelId,
      }

      noteProvider.methods.createNote(newNote).then((response) => {})
    },
  },
}
</script>
